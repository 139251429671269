<template>
  <v-row class="forgotPasswordContainer">
    <v-col md="12" sm="12" style="display: flex; justify-content: center; align-items: center">
      <div class="formDiv">
        <v-form>
          <v-container>
            <v-row>
              <v-col style="
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                ">
                <v-img :src="require('@/assets/general/logoLogin.png')" width="120" height="110"></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col style="
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                ">
                <v-img :src="require('@/assets/general/success-standard-line.png')"></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="textDecorationBold" style="font-size: 32px; color: var(--v-primary-base)"><b>{{
                    $t("emailSentSuccess")
                }}</b></span>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row>
                  <v-col><span class="textFontDecoration" style="font-size: 16px">{{
                      $t("emailWillSent")
                  }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col><span class="textFontDecoration" style="font-size: 16px">{{
                      $t("emailResetLink")
                  }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col><span class="textFontDecoration" style="font-size: 16px">{{
                      $t("emailConfirm")
                  }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col><span class="textFontDecoration" style="font-size: 16px">{{ $t("emailNoReceive") }}
                      <a class="successColor" style="text-decoration: underline" v-on:click="resendEmail">
                        {{ $t("emailResend") }}
                      </a></span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn large outlined color="#263573" @click="loginRedirect" style="width: 100%"
                  class="textFontDecoration btnContainer">
                  {{ $t("backToLogin") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
    </v-col>
  </v-row>
</template>

<script>
/* 
  Name:ForgotPasswordSent
  Description: ForgotPasswordSent view is a page that appears when user enter the email for the password reset.
*/
import { mapActions } from "vuex";
export default {
  name: "ForgotPasswordSent",
  created() { },
  data() {
    return {
      email: null,
    };
  },
  computed: {},
  methods: {
    ...mapActions(["onLogin"]),

    loginRedirect() {
      this.$router.push("/login");
    },
    resendEmail() {
      true
    },
  },
};
</script>

<style scoped>
.forgotPasswordContainer {
  height: 100vh;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(230, 235, 247, 1) 100%);
}

.btnContainer {
  text-transform: capitalize;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  font-size: 15px;
}

.formDiv {
  width: 34%;
}

@media screen and (max-width: 959px) {
  .formDiv {
    width: 100%;
  }
}
</style>
